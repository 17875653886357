import * as React from "react"
import HeaderPrimary from "./headerPrimary"

const Header = ({ children }) => (
  <header className="region region--header">
    <HeaderPrimary />
    {children}
  </header>
)

export default Header
