import {
  getLocalStorage,
  isBrowser,
} from "../localStorageService/localStorageService"

const queryString = isBrowser && window.location.search
const urlParams = new URLSearchParams(queryString)
const sessionToken = urlParams.get("session_id")

export const checkSessionToken = () => {
  if (getLocalStorage("session_id") && sessionToken) {
    const stripeToken = getLocalStorage("session_id")

    if (stripeToken === sessionToken) {
      return true
    }
  }
  return false
}
