import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import {
  axiosBackOfficePostRequest,
  axiosCheckoutSessionRequest,
} from "../API/API"
import {
  getLocalStorage,
  removeFromLocalStorage,
  setLocalStorage,
} from "../localStorageService/localStorageService"
import { checkSessionToken } from "../utils/checkSessionToken"
import Container from "../components/block/container"
import Layout from "../components/layout"
import Header from "../components/regions/header"
import Seo from "../components/seo"
import Loader from "../components/item/loader"
import FieldText from "../components/field/fieldText"
import FieldButton from "../components/field/fieldButton"

const CreateAccount = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isAccountCreated, setIsAccountCreated] = useState(true)

  const clientInfo = getLocalStorage("sideline-client-info")
  const session_id = getLocalStorage("session_id")
  const api_key = getLocalStorage("api_key")

  const createAccount = async () => {
    setIsLoading(true)
    axiosCheckoutSessionRequest(session_id, api_key)
      .then(response => {
        removeFromLocalStorage("api_key")
        return axiosBackOfficePostRequest({
          ...clientInfo,
          subscription_id: response.data.subscription,
        }).then(response => {
          if (response.status === 201) {
            setLocalStorage("client-domain", clientInfo.domain)
            setLocalStorage("client-email", clientInfo.email)
            removeFromLocalStorage("sideline-client-info")
            setIsAccountCreated(true)
            navigate(`/success?session_id=${session_id}`)
          }
        })
      })
      .catch(error => {
        if (error.response.status === 500) {
          setIsAccountCreated(false)
          setIsLoading(false)
        } else {
          navigate("/")
        }
      })
  }

  useEffect(() => {
    if (!checkSessionToken()) {
      navigate("/")
      return
    }
    createAccount()
  }, [])

  return (
    <>
      <Header />
      <Layout>
        <Seo title="Create Account" pageClass="page--create-account" />
        {!checkSessionToken() ? (
          <>
            <Container classes="block--page-top-content block--page-top-content--success"></Container>
          </>
        ) : (
          <>
            <Container classes="block--page-top-content block--page-top-content--success">
              {isLoading ? (
                <Loader loaderMsg="Processing..." />
              ) : (
                <>
                  {!isAccountCreated && (
                    <>
                      <FieldText>
                        <h1 className="text text--centered">
                          Something went wrong
                        </h1>

                        <p className="text text--centered">
                          Click the button below to try again
                        </p>
                      </FieldText>
                      <FieldButton>
                        <button
                          onClick={() => createAccount()}
                          className="button button--big button--red"
                        >
                          Try again
                        </button>
                      </FieldButton>
                    </>
                  )}
                </>
              )}
            </Container>
          </>
        )}
      </Layout>
    </>
  )
}

export default CreateAccount
