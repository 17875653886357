import React, { useState } from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Navigation from "../block/navigation"
import NavItem from "../item/navItem"
import Container from "../block/container"

const HeaderPrimary = () => {
  const [navOpen, setNavOpen] = useState(false)
  const handleToggle = () => {
    setNavOpen(prev => !prev)
    !navOpen
      ? document.body.classList.add("no-scroll")
      : document.body.classList.remove("no-scroll")
  }

  return (
    <div className="region region--header--primary">
      <div className="region__inner">
        <Container classes="block--branding">
          <Link to="/" title="Home" rel="home" className="site-logo">
            <StaticImage
              as="figure"
              className="field field--image"
              quality="90"
              placeholder="tracedSVG"
              src="../../assets/svg/logo.svg"
              alt="Home"
            />
          </Link>
        </Container>

        <Navigation
          title="Main navigation"
          classes={`menu--main${navOpen ? " open" : ""}`}
        >
          <NavItem path="/features" text="Features" />
          <NavItem path="/use-cases" text="Use Cases" />
          <NavItem path="/pricing" text="Pricing" />
        </Navigation>

        <Container classes="block--header-buttons">
          <Link to="/sign-up" className="button button--small button--red">
            Get Started
          </Link>
          <Link
            to="/login"
            className="button button--small button--transparent"
          >
            Login
          </Link>
        </Container>

        <Container
          classes="block--menu-trigger"
          navopen={navOpen}
          onClick={() => setNavOpen(!navOpen)}
        >
          <button
            className={`button button--menu-trigger${navOpen ? " open" : ""}`}
            onClick={handleToggle}
          >
            {navOpen ? "Close" : "Open"} Navigation
          </button>
        </Container>
      </div>
    </div>
  )
}

export default HeaderPrimary
